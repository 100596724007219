import CustomLayout from '@/common/Layout';
import React, { useState, useEffect } from 'react';
import CloningPage from './components/Cloning';
import InititateEnvelopePage2 from './components/InitiateEnvelopePage2';
import { useDispatch, useSelector } from 'react-redux';
import { history } from 'app/history';
import { useParams } from 'react-router-dom';
import { Form, message } from 'antd';
import { getclonebyid } from './redux/slice';
import {
  getPlants,
  getfunctions,
  getcategory,
  getactivity,
  getIONum,
  getCOSTNum,
  getsubCategory
} from '../dashboard/redux/slice';

import {
  createEnvelop,
  createStandardEnvelop
} from '../initiate-envelope/redux/slice';

function ActionsRequired() {
  const dispatch = useDispatch();
  const params = useParams();
  const [form] = Form.useForm();
  const [current, setCurrent] = useState(0);
  const [selectedOption, setSelectedOption] = useState(null);
  const [itemList, setItemList] = useState([{ id: 1, value: '', envtype: '' }]);
  const [mergedPdfBlob, setMergedPdfBlob] = useState(null);
  const [initiate, setInitiate] = useState(false);
  const [formValues, setformValues] = useState({});
  const [signOrder, setSignOrder] = useState(true);
  const [fileList, setFileList] = useState([]);
  const [approvers, setApprovers] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [search, setSearch] = useState('');
  const [departmentSearch, setDepartmentSearch] = useState('');
  const [fieldsDisabled, setFieldsDisabled] = useState(false);
  const [ioBalanceAmount, setIoBalanceAmount] = useState(0);
  const [ionum, setIonum] = useState('');
  const [ioData, setIoData] = useState({});
  const [costnum, setCostnum] = useState('');
  const [costData, setCostData] = useState({});
  const [nfaAmount, setNfaAmount] = useState(0);
  const [isValidNfa, setIsValidNfa] = useState(true);
  const [isDisabled, setIsDisabled] = useState(false);
  const [departmentt, setDepartmentt] = useState('');
  const [plant, setPlant] = useState('');

  const env_mode = 'edit';
  const { cloning_data } = useSelector((state) => state.cloningdataList);
  const {
    get_plants,
    get_functions,
    get_category,
    get_subcategorylist,
    get_activity,
    get_ioNum,
    get_costNum
  } = useSelector((state) => state.dashboard);

  const plants = get_plants;
  const functions = get_functions;
  const get_COSTNumber = get_costNum;
  const get_IONumbers = get_ioNum;
  const category = get_category;
  const subcategory = get_subcategorylist;
  const activity = get_activity;

  const env_type = localStorage.getItem('env_type');
  console.log('env_type', cloning_data);

  // console.log('get_subcategorylist', get_subcategorylist);

  useEffect(() => {
    handleGetCloneData();
  }, [params]);

  useEffect(() => {
    // handlegetLocations();
    handleGetCloneData();
    handlegetPlants();
    handlegetfunctions();
    handlegetcategory();
    handlegetactivity();
    handlegetIONum();
    handlegetCOSTNum();
    handlegetsubcategory();
  }, []);

  const apiCallVal = JSON.parse(localStorage.getItem('apiCallValues')) || {};
  // console.log('apiCallVal', apiCallVal);
  const data = apiCallVal?.env_type === cloning_data?.type;
  // console.log('data', data);

  useEffect(() => {
    setIsDisabled(
      cloning_data?.type === 'standard' || cloning_data?.type === 'custom'
    );
  }, [cloning_data]);

  useEffect(() => {
    const selectedCategoryId =
      apiCallVal?.category_id || cloning_data?.category_id;
    // console.log('data', apiCallVal?.category_id);
    if (selectedCategory !== null) {
      // console.log('Selected Category in Parent:', selectedCategory);
      const payload = selectedCategoryId;
      console.log('selected_payload', payload);
      // API call logic here based on selectedCategory
      dispatch(getsubCategory(payload));
    }
  }, [selectedCategory]);

  useEffect(() => {
    if (cloning_data) {
      // // Fetch subcategories for the selected category
      // setSelectedCategory(cloning_data?.category_id);
      const selectedCategoryId = selectedCategory || cloning_data?.category_id;
      // apiCallVal?.category_id || cloning_data?.category_id;

      const selectedCategoryObj = category.find(
        (cat) => cat.id === selectedCategoryId
      );
      // console.log('setSelectedCategory123', selectedCategoryObj.category_name);
      form.setFieldsValue({ sub_category_id: undefined });
      // Get the category name like 'Capex' or 'Opex'
      const selectedCategoryName = selectedCategoryObj
        ? selectedCategoryObj.category_name
        : '';
      // console.log('setSelectedCategory123', selectedCategoryName);
      setSelectedCategory(selectedCategoryName);
      // console.log('setSelectedCategory123', selectedCategoryName);
      if (handleCategoryChangeFromChild) {
        // handleCategoryChangeFromChild(cloning_data?.category_id);
        // console.log('456selectedCategoryId', selectedCategoryId);
        handleCategoryChangeFromChild(selectedCategoryId);
      }
      setFieldsDisabled(true);
      const initialBalance =
        cloning_data?.balance_amount || ioBalanceAmount || 0;

      form.setFieldsValue({
        // plant_id: cloning_data?.plant_id || '',
        plant_id: data
          ? apiCallVal?.plant_id || ''
          : cloning_data?.plant_id || '',
        // department_id: cloning_data?.department_id || '',
        department_id: data
          ? apiCallVal?.department_id || ''
          : cloning_data?.department_id || '',
        // nfa_amount: cloning_data?.nfa_amount || '',
        nfa_amount: data
          ? apiCallVal?.nfa_amount || ''
          : cloning_data?.nfa_amount || '',
        balance_amount: initialBalance || '',
        // balance_amount: data
        //   ? apiCallVal?.balance_amount || ''
        //   : cloning_data?.balance_amount || '',
        // category_id: cloning_data?.category_id || '',
        // category_id: data
        //   ? // ? apiCallVal?.category_id || ''
        //     selectedCategory || ''
        //   : cloning_data?.category_id || '',

        category_id: selectedCategory,

        // title: cloning_data?.title || '',
        title: data ? apiCallVal?.title || '' : cloning_data?.title || '',
        // description: cloning_data?.title || '',
        description: data
          ? apiCallVal?.description || ''
          : cloning_data?.description || '',
        // emailmessage: cloning_data?.message || '',
        emailmessage: data
          ? apiCallVal?.emailmessage || ''
          : cloning_data?.message || '',

        // sub_category_id: cloning_data?.sub_category_id || '',
        sub_category_id: data
          ? apiCallVal?.sub_category_id || ''
          : cloning_data?.sub_category_id || '',
        // io_no_id: cloning_data?.io_no?.id || '',
        io_no_id: data
          ? apiCallVal?.io_no_id || ''
          : cloning_data?.io_no?.id || '',
        // io_amount: cloning_data?.io_no?.amount || '',
        io_amount: data
          ? apiCallVal?.io_amount || ''
          : cloning_data?.io_no?.amount || '',
        // io_balance_amount: cloning_data?.io_no?.balance_amount || '',
        io_balance_amount: data
          ? apiCallVal?.io_balance_amount || ''
          : cloning_data?.io_no?.balance_amount || '',
        // activity_id: cloning_data?.activity_id || ''
        activity_id: data
          ? apiCallVal?.activity || ''
          : cloning_data?.activity_id || ''
      });
    }
  }, [cloning_data, ioBalanceAmount, form]);

  useEffect(() => {
    if (
      env_type === 'custom' ||
      env_type === 'standard' ||
      // env_type !== 'ad_hoc'
      env_mode === 'edit'
    ) {
      if (cloning_data?.envelope_approvers) {
        let selectedOption = [];
        let itemList = [];
        cloning_data?.envelope_approvers.forEach((item) => {
          // console.log('item_items', item?.envelope_approver);
          selectedOption = [
            ...selectedOption,
            {
              name: item?.envelope_approver?.display_name,
              email: item?.envelope_approver?.email,
              job_title: item?.envelope_approver?.job_title,
              approver_id: item?.approver_id
            }
          ];
          itemList = [
            ...itemList,
            {
              id: item.id,
              value: item.email,
              envtype: item.id,
              approver_id: item?.id
            }
          ];
        });
        setSelectedOption(selectedOption);
        setApprovers(selectedOption);
        setItemList(itemList);
      }
    }
  }, [cloning_data]);

  const handleGetCloneData = () => {
    let payload = {
      id: params.id
    };
    dispatch(getclonebyid(payload));
  };

  const handlegetPlants = () => {
    dispatch(getPlants());
  };

  const handlegetfunctions = () => {
    dispatch(getfunctions());
  };

  const handlegetcategory = () => {
    dispatch(getcategory());
  };

  const handlegetactivity = () => {
    dispatch(getactivity());
  };

  const handlegetIONum = () => {
    dispatch(getIONum());
  };

  const handlegetCOSTNum = () => {
    dispatch(getCOSTNum());
  };

  const handlegetsubcategory = () => {
    dispatch(getsubCategory());
  };

  const handlePlant = (value) => {
    setPlant(value);
  };
  const handleDepartmentt = (value) => {
    setDepartmentt(value);
  };

  const handleSearch = (e) => {
    setSearch(e);
  };

  const handleDepartmentSearch = (e) => {
    setDepartmentSearch(e);
  };

  useEffect(() => {
    console.log('search', search);
    const payload = { search: search };
    dispatch(getPlants(payload));
  }, [search]);

  const handleCategoryChange = (e) => {
    const selectedValue = e.target.value;
    // setSelectedCategory(selectedValue); // Update local state
    // console.log('selected_123Value', selectedValue);

    // Find the category object from the category array using the selected id
    const selectedCategoryObj = category.find(
      (cat) => cat.id === selectedValue
    );
    form.setFieldsValue({ sub_category_id: undefined });
    // Get the category name like 'Capex' or 'Opex'
    const selectedCategoryName = selectedCategoryObj
      ? selectedCategoryObj.category_name
      : '';

    setSelectedCategory(selectedCategoryName);
    // console.log('123selected123Value', selectedCategory);
    if (handleCategoryChangeFromChild) {
      // console.log('123selected123Value', selectedValue);
      handleCategoryChangeFromChild(selectedValue);
    }
  };

  const handleIONumChange = (value) => {
    const selectedIO = get_IONumbers.find((io) => io.id === value);
    // console.log('selectedIO', selectedIO.amount);
    setIonum(value);
    console.log('IO_Selected', ionum);
    if (selectedIO) {
      setIoData(selectedIO); // Store selected I/O data
      setIoBalanceAmount(selectedIO.balance_amount);
      form.setFieldsValue({
        io_amount: selectedIO.amount,
        io_balance_amount: selectedIO.balance_amount
      });
      setFieldsDisabled(true); // Disable the fields

      console.log('nfaAmount', nfaAmount);
      if (nfaAmount) {
        const fakeEvent = { target: { value: nfaAmount } }; // Create a fake event object
        handleNfaAmountChange(fakeEvent); // Trigger the NFA amount change logic
      }
    }
  };

  console.log('ioData', ioData);
  console.log('CostData', costData);

  const handleCostNumChange = (value) => {
    const selectedCostnum = get_COSTNumber.find((cost) => cost.id === value);
    setCostnum(value); // Update the cost number state
    console.log('CostNum_Selected', costnum);

    if (selectedCostnum) {
      setCostData(selectedCostnum); // Store selected cost data
      setIoBalanceAmount(selectedCostnum.balance_amount);

      // Update only cost center related fields in the form
      form.setFieldsValue({
        cost_center_amount: selectedCostnum.amount,
        cost_center_balance_amount: selectedCostnum.balance_amount
      });

      // Trigger NFA amount change logic, if applicable
      if (nfaAmount) {
        const fakeEvent = { target: { value: nfaAmount } }; // Create a fake event object
        handleNfaAmountChange(fakeEvent);
      }

      // Ensure category_id remains unchanged
      const currentCategory = form.getFieldValue('category_id');
      setSelectedCategory(currentCategory); // Synchronize with form state
      setFieldsDisabled(true); // Disable the fields
    }
  };

  const handleNfaAmountChange = (e) => {
    const nfa = parseFloat(e.target.value) || 0;
    setNfaAmount(nfa);

    // Update the balance_amount field dynamically
    // const calculatedBalance = ioBalanceAmount > 0 ? ioBalanceAmount - nfa : nfa;
    const ioBalanceAmount_round =
      cloning_data?.balance_amount || ioBalanceAmount;

    const calculatedBalance = ioBalanceAmount_round - nfa;
    // console.log('calculatedBalance', calculatedBalance);
    // console.log('ioBalanceAmount', ioBalanceAmount_round);
    // console.log('nfa', nfa);
    // Validate balance amount
    if (nfa > ioBalanceAmount_round) {
      setIsValidNfa(false); // Mark as invalid
      message.error('NFA amount cannot exceed the I/O Balance Amount');
      return; // Exit to prevent setting invalid values
    }
    setIsValidNfa(true);
    // console.log('calculatedBalance', calculatedBalance);
    form.setFieldsValue({
      // balance_amount: calculatedBalance > 0 ? calculatedBalance : 0
      balance_amount: calculatedBalance
    });
    // console.log('Updated calculatedBalance:', calculatedBalance);
  };

  const handleCategoryChangeFromChild = (value) => {
    // console.log('handleCategoryChangeFromChild', value);
    setSelectedCategory(value);
  };

  const handleFormSubmit = () => {
    setInitiate(true);
    let updatd = [];
    // console.log('selectedOption123456', selectedOption);
    selectedOption.map((item, index) => {
      var obj = {};
      obj['name'] = item.display_name;
      obj['email'] = item.email;
      obj['approver_id'] = item.approver_id;
      signOrder ? (obj['sequence'] = index + 0) : '';
      obj['sign_in'] = index === 0 ? true : false;
      updatd.push(obj);
    });
    // console.log('fileList in Parent', mergedPdfBlob);
    let formData = new FormData();

    fileList.forEach((file) => {
      formData.append(`documents`, file);
    });
    // console.log('type', env_type);

    const apiCallValues = localStorage.getItem('apiCallValues');
    formData.append('type', env_type ? env_type : 'ad-hoc');
    // console.log('formValues?.message', formValues?.emailmessage);
    // console.log('setformValues', formValues);
    if (env_type !== 'standard' && env_type !== 'custom') {
      formData.append('title', formValues?.title);
      formData.append('plant_id', formValues?.plant_id);
      formData.append('department_id', formValues?.department_id);
      formData.append('approvers', JSON.stringify(updatd));
      formData.append('description', formValues?.description);
      formData.append('message', formValues?.emailmessage);
      formData.append('nfa_amount', formValues?.nfa_amount);
      formData.append('balance_amount', formValues?.balance_amount);
      formData.append('signing_order', signOrder);
    } else {
      formData.append('approvers', JSON.stringify(updatd));
      formData.append('description', formValues?.description);
      formData.append('message', formValues?.emailmessage);
      // formData.append('flow_id', localStorage.getItem('flow_id'));
      formData.append('signing_order', signOrder);
      formData.append('title', localStorage.getItem('envelope_name'));
      if (apiCallValues) {
        const parsedValues = JSON.parse(apiCallValues);
        formData.append('category_id', parsedValues.category_id);
        formData.append('sub_category_id', parsedValues.sub_category_id);
        formData.append('plant_id', parsedValues.plant_id);
        formData.append('department_id', parsedValues.department_id);
        formData.append('nfa_amount', parsedValues.nfa_amount);
        formData.append('balance_amount', parsedValues.balance_amount);
        // formData.append('io_no_id', parsedValues.io_no_id);
        formData.append(
          'flow_id',
          parsedValues.flow_id ? parsedValues.flow_id : ''
        );
        formData.append(
          'io_no_id',
          parsedValues.io_no_id ? parsedValues.io_no_id : ''
        );
        formData.append(
          'io_amount',
          parsedValues.io_amount ? parsedValues.io_amount : ''
        );
        formData.append('activity', parsedValues.activity);
        // formData.append('cost_center', parsedValues.cost_center);
        formData.append(
          'cost_centr_id',
          parsedValues.cost_centr_id ? parsedValues.cost_centr_id : ''
        );
        formData.append(
          'cost_center_amount',
          parsedValues.cost_center_amount ? parsedValues.cost_center_amount : ''
        );
        formData.append(
          'cost_center_balance_amount',
          parsedValues.cost_center_balance_amount
        );
      }
    }

    const dispatchAction =
      env_type === 'standard' || env_type === 'custom'
        ? createStandardEnvelop
        : createEnvelop;

    // dispatch(createEnvelop(formData)).then((response) => {
    dispatch(dispatchAction(formData)).then((response) => {
      if (response.payload.success) {
        setInitiate(false);
        setSignOrder(false);
        history.push('/dashboard');
      } else {
        setInitiate(false);
      }
    });
  };

  return (
    <CustomLayout sider={false} tracker={false} header={false}>
      {current == 0 ? (
        <CloningPage
          cloning_data={cloning_data}
          form={form}
          current={current}
          setCurrent={setCurrent}
          selectedOption={selectedOption}
          setSelectedOption={setSelectedOption}
          mergedPdfBlob={mergedPdfBlob}
          setMergedPdfBlob={setMergedPdfBlob}
          initiate={initiate}
          setInitiate={setInitiate}
          formValues={formValues}
          setformValues={setformValues}
          setSignOrder={setSignOrder}
          signOrder={signOrder}
          setFileList={setFileList}
          fileList={fileList}
          itemList={itemList}
          setItemList={setItemList}
          approvers={approvers}
          setApprovers={setApprovers}
          env_mode={env_mode}
          selectedCategory={selectedCategory}
          // setSelectedCategory={setSelectedCategory}
          plants={plants}
          functions={functions}
          get_COSTNumber={get_COSTNumber}
          get_IONumbers={get_IONumbers}
          category={category}
          subcategory={subcategory}
          activity={activity}
          search={search}
          setSearch={setSearch}
          departmentSearch={departmentSearch}
          setDepartmentSearch={setDepartmentSearch}
          fieldsDisabled={fieldsDisabled}
          setFieldsDisabled={setFieldsDisabled}
          ioBalanceAmount={ioBalanceAmount}
          setIoBalanceAmount={setIoBalanceAmount}
          handleCategoryChange={handleCategoryChange}
          handleIONumChange={handleIONumChange}
          handleCostNumChange={handleCostNumChange}
          isValidNfa={isValidNfa}
          handleNfaAmountChange={handleNfaAmountChange}
          isDisabled={isDisabled}
          handleDepartmentSearch={handleDepartmentSearch}
          handleSearch={handleSearch}
          handlePlant={handlePlant}
          handleDepartmentt={handleDepartmentt}
          departmentt={departmentt}
          plant={plant}
        />
      ) : (
        <InititateEnvelopePage2
          current={current}
          setCurrent={setCurrent}
          functions={get_functions}
          plants={get_plants}
          category={get_category}
          activity={get_activity}
          subcategory={get_subcategorylist}
          get_IONumbers={get_ioNum}
          get_COSTNumber={get_costNum}
          selectedOption={selectedOption}
          // setSelectedOption={setSelectedOption}
          mergedPdfBlob={mergedPdfBlob}
          setMergedPdfBlob={setMergedPdfBlob}
          handleFormSubmit={handleFormSubmit}
          initiate={initiate}
          setInitiate={setInitiate}
          formValues={formValues}
          setformValues={setformValues}
          // env_type={env_type}
          setSignOrder={setSignOrder}
          signOrder={signOrder}
          // handleCategoryChangeFromChild={handleCategoryChangeFromChild}
        />
      )}
      {/* <div>testing site for checking</div> */}
    </CustomLayout>
  );
}

export default ActionsRequired;
