// import React, { useState } from 'react';
import styles from './index.module.less';
import { Form, Space } from 'antd';
import CustomButton from '@/common/CustomButton';
import CustomModal from '@/common/CustomModal';
import FormItem from '@/common/FormItem';

function ApproveModal({
  // fileList,
  setFileList,
  onFinish,
  loading,
  modalVisible,
  setModalVisible
}) {
  // const [approversign, setApproversign] = useState('');
  const [form] = Form.useForm();
  // console.log('approve_loading', loading);
  return (
    <CustomModal
      style={{ marginTop: '90px' }}
      visible={modalVisible === 'approved' ? true : false}
      className={styles.background}
      title={'Approve the document'}
      onCancel={() => {
        setModalVisible('');
        setFileList([]);
      }}
      /* width={'40%'} */
      footer={null}
    >
      <Form
        form={form}
        layout="vertical"
        className="ant-form ant-form-vertical"
        onFinish={onFinish}
      >
        {/* <FormItem name="action" value="approved"></FormItem> */}
        <h3 className={styles.background}>Are you sure you want to approve?</h3>
        <br />
        <FormItem className="text-right" style={{ marginBottom: '0px' }}>
          <Space>
            <CustomButton
              htmlType="button"
              onClick={() => {
                setModalVisible('');
                setFileList([]);
              }}
            >
              Cancel
            </CustomButton>
            <CustomButton
              type="primary1"
              htmlType="submit"
              loading={loading}
              className={styles.approvebutton}
            >
              Approve
            </CustomButton>
          </Space>
        </FormItem>
      </Form>
    </CustomModal>
  );
}

export default ApproveModal;
