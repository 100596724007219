import React, { useState, useCallback, useEffect, useMemo } from 'react';
import {
  Form,
  Badge,
  Col,
  Row,
  Upload,
  Input,
  Image,
  Collapse,
  message,
  Radio,
  Select
} from 'antd';
import CustomButton from '@/common/CustomButton';
import upload from '../../images/empty.svg';
import Close from '../../images/Closesvg';
import styles from './index.module.less';
import CustomCollapse from '@/common/CustomCollapse';
import {
  DownOutlined,
  HolderOutlined,
  PlusCircleFilled
} from '@ant-design/icons';
import TextArea from 'antd/lib/input/TextArea';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import FormItem from 'common/FormItem';
import Delete from '../../images/Deletesvg';
import DisplayFiles from '../DisplayFiles';
import { blobToFile, removeDuplicatesFromArray } from 'utilities/helpers';
import { createPDF, mergePDF, pdfArrayToBlob } from 'pdf-actions';
import { ReactSearchAutocomplete } from 'react-search-autocomplete';
import { Link } from 'react-router-dom';
import LocalStorage from 'utilities/localStorage';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import {
  getAllApprovers,
  getAllstandardenv,
  getAllenvByID
} from '../../../initiate-envelope/redux/slice';

const { Panel } = Collapse;
const { Dragger } = Upload;

const cloningPage = ({
  cloning_data,
  form,
  current,
  setCurrent,
  selectedOption,
  setSelectedOption,
  setMergedPdfBlob,
  formValues,
  setformValues,
  fileList,
  setFileList,
  setItemList,
  itemList,
  approvers,
  env_mode,
  selectedCategory,
  activity,
  plants,
  functions,
  get_COSTNumber,
  get_IONumbers,
  category,
  subcategory,
  // setSearch,
  fieldsDisabled,
  ioBalanceAmount,
  handleCategoryChange,
  handleIONumChange,
  handleCostNumChange,
  isValidNfa,
  handleNfaAmountChange,
  isDisabled,
  handleDepartmentSearch,
  handleSearch,
  handleDepartmentt,
  handlePlant
}) => {
  const Option = Select;
  const { allApprovers } = useSelector((state) => state.approvers);

  const [approverSearch, setApproverSearch] = useState('');
  // const [setDepartmentt] = useState('');
  // const [setPlant] = useState('');
  const [setIOSearch] = useState('');
  const [setCOSTSearch] = useState('');
  const [setactivitySearch] = useState('');
  const [setDepartmentSearch] = useState('');
  const [setCompanyId] = useState();

  const dispatch = useDispatch();
  const params = useParams();

  console.log('isDisabled', isDisabled);

  const env_type = cloning_data?.type;
  localStorage.setItem('env_type', env_type);
  localStorage.setItem('envelope_name', cloning_data?.title);
  localStorage.setItem('cloning_data', cloning_data);
  console.log('child_selectedCategory', selectedCategory),
    useEffect(() => {
      if (env_type === 'custom' && env_type !== null) {
        handleGetStandardEnv();
      }
      if (env_type === 'standard' && env_type !== null) {
        handleGetStandardEnv();
      }
      if (env_mode === 'edit' && env_mode !== null) {
        handleGetEnv();
      }
    }, []);

  const handleGetEnv = () => {
    dispatch(getAllenvByID(params.id));
  };

  const handleGetStandardEnv = () => {
    const payload = {
      id: params.id
    };
    dispatch(getAllstandardenv(payload));
  };

  const mergePDFHandler = async () => {
    let files = [...fileList];
    const pdfDocs = [];

    for (let i = 0; i < files?.length; i++) {
      const file = files[i];
      const pdfFile = await createPDF.PDFDocumentFromFile(file);
      pdfDocs.push(pdfFile);
    }

    const mergedPdfFile = await (await mergePDF(pdfDocs)).save();
    const pdfBlob = pdfArrayToBlob(mergedPdfFile);
    const finalBlob = blobToFile(pdfBlob, 'adhoc_envelope_merged.pdf');

    setMergedPdfBlob(finalBlob);
  };

  let selectedRows = [];
  let filtered_rows = allApprovers?.filter(function (cv) {
    return !selectedOption?.find(function (e) {
      return e?.email === cv?.email;
    });
  });
  if (selectedOption?.length) {
    filtered_rows?.map((item) => {
      selectedRows.push({
        name: item.display_name,
        email: item.email,
        id: item.id
      });
    });
  } else {
    allApprovers?.map((item) => {
      selectedRows.push({
        name: item.display_name,
        email: item.email,
        id: item.id
      });
    });
  }

  const addInput = () => {
    var highestValue = 0; //keep track of highest value
    // loop through array of objects
    for (var i = 0, len = itemList?.length; i < len; i++) {
      var value = Number(itemList[i]['id']);
      if (value > highestValue) {
        highestValue = value;
      }
    }
    let id = highestValue + 1;
    setItemList([...itemList, { id: id, value: '', envtype: '' }]);
  };

  const approverPayload = useMemo(() => {
    return { type: 'users', search: approverSearch };
  }, [approverSearch]);

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      dispatch(getAllApprovers(approverPayload));
    }, 500);

    // Cleanup the timeout to avoid unnecessary dispatch if the component unmounts or the dependency changes
    return () => clearTimeout(timeoutId);
  }, [dispatch, approverPayload]);

  // Handle change in the TextArea
  const handleTextAreaChange = (e) => {
    const value = e.target.value;
    // setEmailMessage(value);
    localStorage.setItem('emailmessage', value); // Store the value in localStorage
    form.setFieldsValue({ emailmessage: value }); // Update the form field value
  };

  const removeInput = (id) => {
    // console.log('Before remove:', itemList, selectedOption);
    let itemValues = [...itemList];
    let selectedOptions = [...selectedOption];
    itemValues.splice(id, 1);
    selectedOptions.splice(id, 1);
    // console.log('After remove:', itemValues, selectedOptions);
    setItemList(itemValues);
    setSelectedOption(selectedOptions);
  };

  const handleDrop = (droppedItem) => {
    if (!droppedItem.destination) return;
    var updatedList = [...selectedOption];
    var updatedItemList = [...itemList];
    const [reorderedItem] = updatedList.splice(droppedItem.source.index, 1);
    updatedList.splice(droppedItem.destination.index, 0, reorderedItem);
    const [reorderedItemList] = updatedItemList.splice(
      droppedItem.source.index,
      1
    );
    updatedItemList.splice(droppedItem.destination.index, 0, reorderedItemList);
    setItemList(updatedItemList);
    setSelectedOption(updatedList);
  };
  const handleOnChangePdfUpload = ({ fileList: onChangeFileList }) => {
    let files = fileList.concat(onChangeFileList.map((o) => o.originFileObj));

    setFileList(removeDuplicatesFromArray(files, 'name'));
  };

  const handleOnDropPdfUpload = (e) => {
    let files = fileList.concat(e.dataTransfer.files);
    setFileList(removeDuplicatesFromArray(files, 'name'));
  };

  const handleCompany = (value) => {
    setCompanyId(value);
  };

  // const handlePlant = (value) => {
  //   setPlant(value);
  // };
  // const handleDepartmentt = (value) => {
  //   setDepartmentt(value);
  // };
  // const handleSearch = (e) => {
  //   setSearch(e);
  // };
  const handleIOSearch = (value) => {
    setIOSearch(value);
  };
  const handlecostSearch = (value) => {
    setCOSTSearch(value);
  };
  const handleActivitySearch = (value) => {
    setactivitySearch(value);
  };
  const handleDepartmenttSearch = (e) => {
    setDepartmentSearch(e);
  };

  useEffect(() => {
    localStorage.setItem('formValues', JSON.stringify(formValues));
    const { description, approvers, message } = formValues ? formValues : {};
    form.setFieldsValue({
      description: description,
      approvers: approvers,
      message: message
    });
  }, [formValues]);

  const onFinish = (values) => {
    console.log('values', values);
    if (!isValidNfa) {
      message.error(
        'Please change the NFA Amount in the form before submitting'
      );
      return; // Prevent form submission
    }
  };

  const handleNext = () => {
    let err = 0;
    const description = form.getFieldValue('description');
    // const approvers = form.getFieldValue('approvers');
    const approvers = selectedOption;
    const emailmessage = form.getFieldValue('emailmessage');
    // console.log('test_description', description);
    // console.log('test_approvers', approvers);
    // console.log('test_selectedOption', selectedOption);
    // console.log('test_emailmessage', emailmessage);
    if (
      description === '' ||
      description === undefined ||
      approvers === '' ||
      approvers === undefined ||
      emailmessage === '' ||
      emailmessage === undefined
    ) {
      err = 1;
    }
    if (err === 1) {
      return message.error('Please enter all required fields');
    }
    setformValues({
      title: form.getFieldValue('title'),
      plant_id: form.getFieldValue('plant_id'),
      department_id: form.getFieldValue('department_id'),
      description: form.getFieldValue('description'),
      approvers: form.getFieldValue('approvers'),
      // message: form.getFieldValue('emailmessage'),
      emailmessage: form.getFieldValue('emailmessage'),
      category_id: form.getFieldValue('category_id'),
      sub_category_id: form.getFieldValue('sub_category_id'),
      nfa_amount: form.getFieldValue('nfa_amount'),
      balance_amount: form.getFieldValue('balance_amount'),
      activity: form.getFieldValue('activity'),
      io_no_id: form.getFieldValue('io_no_id'),
      io_amount: form.getFieldValue('io_amount'),
      io_balance_amount: form.getFieldValue('io_balance_amount'),
      cost_centr_id: form.getFieldValue('cost_centr_id'),
      cost_center_amount: form.getFieldValue('cost_center_amount'),
      cost_center_balance_amount: form.getFieldValue(
        'cost_center_balance_amount'
      )
    });
    // console.log('formValues', formValues);

    // Create the updated form values
    const updatedFormValues = {
      env_type: cloning_data?.type,
      flow_id: cloning_data?.flow_id,
      title: form.getFieldValue('title'),
      plant_id: form.getFieldValue('plant_id'),
      department_id: form.getFieldValue('department_id'),
      description: form.getFieldValue('description'),
      approvers: form.getFieldValue('approvers'),
      emailmessage: form.getFieldValue('emailmessage'),
      category_id: form.getFieldValue('category_id'),
      sub_category_id: form.getFieldValue('sub_category_id'),
      nfa_amount: form.getFieldValue('nfa_amount'),
      balance_amount: form.getFieldValue('balance_amount'),
      activity: form.getFieldValue('activity_id'),
      io_no_id: form.getFieldValue('io_no_id'),
      io_amount: form.getFieldValue('io_amount'),
      io_balance_amount: form.getFieldValue('io_balance_amount'),
      cost_centr_id: form.getFieldValue('cost_centr_id'),
      cost_center_amount: form.getFieldValue('cost_center_amount'),
      cost_center_balance_amount: form.getFieldValue(
        'cost_center_balance_amount'
      )
    };

    // Update localStorage immediately
    localStorage.setItem('apiCallValues', JSON.stringify(updatedFormValues));
    // localStorage.setItem('apiCallVal', JSON.stringify(formValues));
    //
    if (selectedOption?.length > 0 && fileList?.length > 0) {
      mergePDFHandler();
      // console.log('current', current);
      // console.log('fileList', fileList);
      setCurrent(current + 1);
      // <InititateEnvelopePage2 plants={plants} />;
      // console.log('setCurrent', setCurrent);
    } else {
      return message.error(
        'Please select atleast one document and one approver'
      );
    }
  };

  const onSelect = (data) => {
    // console.log('data123', data);
    setSelectedOption([
      ...selectedOption,
      // { name: data?.display_name, email: data?.email }
      { name: data?.name, email: data?.email, approver_id: data?.id }
      // { name: display_name, email: email, id: selectedItem?.id }
    ]);
  };
  // console.log('selectedOption', selectedOption);

  const removeInputFields = useCallback(
    (e, index) => {
      const rows = [...fileList];
      rows.splice(index, 1);
      setFileList(rows);
    },
    [fileList]
  );

  // Update form fields whenever approvers change
  useEffect(() => {
    form.setFieldsValue({ approvers });
  }, [approvers, form]);

  const handleApproverSearch = (text) => {
    setApproverSearch(text);
  };

  const zInd = 998;
  const user = LocalStorage.getItem('gnfa_user');

  const Header = () => {
    return (
      <>
        <div className={`w-100 ${styles.init_env_header}`}>
          <Row>
            <Link to="/dashboard">
              <div className={`df-jc-ac ${styles.x_button}`}>
                <Close />
              </div>
            </Link>
            <Col span={19} className={`${styles.header_text}`}>
              {/* <div className={`w-100 ${styles.init_env_header}`}> */}
              {/* `${localStorage.getItem('title')}`</div> */}
              <p className={styles.HeaderStyles}>
                {' '}
                {/* {cloning_data?.type} envelope{' '} */}
                {cloning_data?.type === 'ad_hoc'
                  ? 'AD Hoc Envelope'
                  : cloning_data?.type === 'standard'
                  ? 'Standard Envelope'
                  : cloning_data?.type === 'custom'
                  ? 'Custom Envelope'
                  : `${cloning_data?.type || ''} envelope`}
              </p>
            </Col>

            <Col className="justify-content-end df-jb-ac" span={4}>
              <CustomButton
                type="primary"
                onClick={(e) => {
                  handleNext(e);
                }}
              >
                Next
              </CustomButton>
            </Col>
          </Row>
        </div>
        {/* )} */}
      </>
    );
  };

  // let chcked = env_type === 'custom' ? true : false;
  const formatResult = (item) => {
    // console.log('item', item);
    return (
      <>
        <span style={{ display: 'block', textAlign: 'left' }}>
          {item?.name} ({item?.email})
        </span>
      </>
    );
  };

  const getEnvelopeType = (type) => {
    const normalizedType = type?.trim().toLowerCase();
    switch (normalizedType) {
      case 'ad_hoc':
        return 'AD Hoc Envelope';
      case 'standard':
        return 'Standard Envelope';
      case 'custom':
        return 'Custom Envelope';
      // default:
      //   return `${type || ''} envelope`;
    }
  };

  return (
    <>
      {/* {envelopeDataLoading ? ( */}
      {/* <div
        style={{ height: '100vh', flexDirection: 'column' }}
        className="df-jc-ac h-100"
      >
        {' '}
        <Spin />
        Please wait
      </div> */}
      {/* ) : ( */}
      <div className={`${styles.mainContainer}`}>
        <Form
          form={form}
          layout="vertical"
          className={`ant-form ant-form-vertical  ${styles.form_width}`}
          onFinish={onFinish}
        >
          <Header />

          {/* AD HOC flow */}
          <div className={`${styles.container}`}>
            {cloning_data?.type === 'ad_hoc' ? (
              <>
                <CustomCollapse
                  expandIcon={({ isActive }) => (
                    <DownOutlined rotate={isActive ? 180 : 0} />
                  )}
                  expandIconPosition="end"
                  bordered={false}
                  defaultActiveKey={['1']}
                >
                  <Panel
                    className={` ${styles.panel}`}
                    header={
                      <Badge
                        className={` ${styles.badge}`}
                        count={1}
                        dot={false}
                      />
                    }
                    // extra="ad_hoc envelope"
                    extra={getEnvelopeType(`${cloning_data?.type || ''}`)}
                    key="1"
                  >
                    <br />
                    <Row gutter={16}>
                      <Col span={12}>
                        <FormItem
                          label="Plant"
                          name="plant_id"
                          rules={[{ required: true, message: 'Select plant' }]}
                        >
                          <Select
                            placeholder="Select plant"
                            onChange={handlePlant}
                            showSearch
                            optionFilterProp="children"
                            onSearch={handleSearch}
                            disabled={isDisabled}
                          >
                            {plants?.map((plant) => (
                              <Option key={plant.id} value={plant.id}>
                                {plant.name}
                              </Option>
                            ))}
                          </Select>
                        </FormItem>
                      </Col>

                      <Col span={12}>
                        <FormItem
                          label="Departiment"
                          name="department_id"
                          rules={[
                            { required: true, message: 'Select department' }
                          ]}
                        >
                          <Select
                            placeholder="Select department"
                            onChange={handleDepartmentt}
                            showSearch
                            optionFilterProp="children"
                            onSearch={handleDepartmentSearch}
                          >
                            {functions?.map((functions) => (
                              <Option key={functions.id} value={functions.id}>
                                {functions.function_name}
                              </Option>
                            ))}
                          </Select>
                        </FormItem>
                      </Col>
                    </Row>
                    <Row gutter={16}>
                      <Col span={12}>
                        <FormItem
                          label="NFA Amount"
                          name="nfa_amount"
                          rules={[
                            {
                              required: true,
                              message: 'Please enter NFA amount'
                            }
                          ]}
                        >
                          <Input placeholder="Enter NFA amount" />
                        </FormItem>
                      </Col>
                      <Col span={12}>
                        <FormItem
                          label="Balance Amount"
                          name="balance_amount"
                          rules={[
                            {
                              required: true,
                              message: 'Please enter balance amount'
                            }
                          ]}
                        >
                          <Input placeholder="Enter balance amount" />
                        </FormItem>
                      </Col>
                    </Row>
                    <Row gutter={16}>
                      <Col span={12}>
                        <FormItem
                          label="Title"
                          name="title"
                          rules={[
                            {
                              required: true,
                              message: 'Please enter the title'
                            }
                          ]}
                        >
                          <Input placeholder="Enter title" />
                        </FormItem>
                      </Col>
                    </Row>
                  </Panel>
                </CustomCollapse>
              </>
            ) : (
              <>
                <CustomCollapse
                  expandIcon={({ isActive }) => (
                    <DownOutlined rotate={isActive ? 180 : 0} />
                  )}
                  expandIconPosition="end"
                  bordered={false}
                  defaultActiveKey={['1']}
                >
                  <Panel
                    className={` ${styles.panel}`}
                    header={
                      <Badge
                        className={` ${styles.badge}`}
                        count={1}
                        dot={false}
                      />
                    }
                    // extra={`${cloning_data?.type || ''} envelope`}
                    extra={getEnvelopeType(`${cloning_data?.type || ''}`)}
                    key="1"
                  >
                    <br />
                    <Row gutter={16}>
                      <Col span={12}>
                        <Form.Item
                          label="Plant"
                          name="plant_id"
                          rules={[{ required: true, message: 'Select plant' }]}
                        >
                          <Select
                            placeholder="Select plant"
                            onChange={handlePlant}
                            showSearch
                            optionFilterProp="children"
                            onSearch={handleSearch}
                            disabled={isDisabled}
                          >
                            {plants.map((plant) => (
                              <Option key={plant.id} value={plant.id}>
                                {plant.name}
                              </Option>
                            ))}
                          </Select>
                        </Form.Item>
                      </Col>
                      <Col span={12}>
                        <Form.Item
                          label="Department"
                          name="department_id"
                          rules={[
                            { required: true, message: 'Select department' }
                          ]}
                        >
                          <Select
                            placeholder="Select department"
                            onChange={handleDepartmentt}
                            showSearch
                            optionFilterProp="children"
                            onSearch={handleDepartmenttSearch}
                            disabled={isDisabled}
                          >
                            {functions.map((functions) => (
                              <Option key={functions.id} value={functions.id}>
                                {functions.function_name}
                              </Option>
                            ))}
                          </Select>
                        </Form.Item>
                      </Col>

                      <Col span={12}>
                        <Form.Item
                          label="Category"
                          name="category_id"
                          rules={[
                            {
                              required: true,
                              message: 'Select a Category type'
                            }
                          ]}
                        >
                          <Radio.Group
                            onChange={handleCategoryChange}
                            value={selectedCategory}
                            disabled={isDisabled}
                          >
                            {category?.map((cat) => (
                              <Radio key={cat.id} value={cat.id}>
                                {cat.category_name}
                              </Radio>
                            ))}
                          </Radio.Group>
                        </Form.Item>
                      </Col>
                      <Col span={12}>
                        <Form.Item
                          label="Sub Category"
                          name="sub_category_id"
                          rules={[
                            { required: true, message: 'Select sub-category' }
                          ]}
                        >
                          <Select
                            placeholder="Select sub-category"
                            disabled={isDisabled}
                          >
                            {subcategory?.map((subcategory) => (
                              <Option
                                key={subcategory.id}
                                value={subcategory.id}
                              >
                                {subcategory.sub_category_name}
                              </Option>
                            ))}
                          </Select>
                        </Form.Item>
                      </Col>
                      {/*  */}

                      {/* Conditionally Render Based on Category */}
                      {
                        // selectedCategory === 'Capex' ||
                        selectedCategory === 1 && (
                          <>
                            <Col span={12}>
                              <Form.Item
                                label="I/O No"
                                // name="io_no"
                                name="io_no_id"
                                rules={[
                                  {
                                    required: true,
                                    message: 'Please enter I/O No'
                                  }
                                ]}
                              >
                                {/* <Input placeholder="Enter I/O No" />  get_IONumbers */}
                                <Select
                                  placeholder="Select I/O No"
                                  // onChange={handleionum}
                                  onChange={handleIONumChange}
                                  showSearch
                                  optionFilterProp="children"
                                  onSearch={handleIOSearch}
                                >
                                  {get_IONumbers?.map((get_IONumber) => (
                                    <Option
                                      key={get_IONumber.id}
                                      value={get_IONumber.id}
                                    >
                                      {get_IONumber.number}
                                    </Option>
                                  ))}
                                </Select>
                              </Form.Item>
                            </Col>
                            <Col span={12}>
                              <Form.Item
                                label="I/O Amount"
                                name="io_amount"
                                rules={[
                                  {
                                    required: true,
                                    message: 'Please enter I/O amount'
                                  }
                                ]}
                              >
                                <Input
                                  placeholder="Enter I/O amount"
                                  disabled={fieldsDisabled}
                                />
                              </Form.Item>
                            </Col>
                            <Col span={12}>
                              <Form.Item
                                label="I/O Balance Amount"
                                name="io_balance_amount"
                                rules={[
                                  {
                                    required: false,
                                    message: 'Please check I/O balance amount'
                                  }
                                ]}
                              >
                                <Input
                                  placeholder="Please check I/O balance amount"
                                  disabled={fieldsDisabled}
                                  value={ioBalanceAmount}
                                />
                              </Form.Item>
                            </Col>
                          </>
                        )
                      }

                      {
                        // selectedCategory === 'Opex' ||
                        selectedCategory === 3 && (
                          <>
                            <Col span={12}>
                              <Form.Item
                                label="Cost Center"
                                // name="cost_center"
                                name="cost_centr_id"
                                rules={[
                                  {
                                    required: true,
                                    message: 'Please enter cost center'
                                  }
                                ]}
                              >
                                {/* <Input placeholder="Enter Cost Center" /> */}
                                <Select
                                  placeholder="Select cost center number"
                                  // onChange={handleionum}
                                  onChange={handleCostNumChange}
                                  showSearch
                                  optionFilterProp="children"
                                  onSearch={handlecostSearch}
                                >
                                  {get_COSTNumber?.map((get_COSTNumbe) => (
                                    <Option
                                      key={get_COSTNumbe.id}
                                      value={get_COSTNumbe.id}
                                    >
                                      {get_COSTNumbe.name}
                                    </Option>
                                  ))}
                                </Select>
                              </Form.Item>
                            </Col>
                            <Col span={12}>
                              <Form.Item
                                label="Cost Center Amount"
                                name="cost_center_amount"
                                rules={[
                                  {
                                    required: true,
                                    message: 'Please enter cost center amount'
                                  }
                                ]}
                              >
                                <Input
                                  placeholder="Enter cost center amount"
                                  disabled={fieldsDisabled}
                                />
                              </Form.Item>
                            </Col>
                            <Col span={12}>
                              <Form.Item
                                label="Cost Center Balance Amount"
                                name="cost_center_balance_amount"
                                rules={[
                                  {
                                    required: false,
                                    message:
                                      'Please check cost center balance amount'
                                  }
                                ]}
                              >
                                <Input
                                  placeholder="Please check cost center balance amount"
                                  disabled={fieldsDisabled}
                                  value={ioBalanceAmount}
                                />
                              </Form.Item>
                            </Col>
                          </>
                        )
                      }
                      <Col span={12}>
                        <Form.Item
                          label="NFA Amount"
                          name="nfa_amount"
                          rules={[
                            {
                              required: true,
                              message: 'Please enter NFA amount'
                            }
                          ]}
                        >
                          <Input
                            placeholder="Enter NFA amount"
                            onChange={handleNfaAmountChange}
                          />
                        </Form.Item>
                      </Col>
                      <Col span={12}>
                        <Form.Item
                          label="Balance Amount"
                          name="balance_amount"
                          rules={[
                            {
                              required: true,
                              message: 'Please enter balance amount'
                            }
                          ]}
                        >
                          <Input
                            // size="large"
                            disabled
                            placeholder="Total invoice amount"
                          />
                        </Form.Item>
                      </Col>

                      <Col span={12}>
                        <Form.Item
                          label="Activity"
                          name="activity_id"
                          rules={[
                            { required: true, message: 'Select activity' }
                          ]}
                        >
                          <Select
                            placeholder="Select activity"
                            onChange={handleCompany}
                            showSearch
                            optionFilterProp="children"
                            onSearch={handleActivitySearch}
                            disabled={isDisabled}
                          >
                            {activity?.map((activity) => (
                              <Option key={activity.id} value={activity.id}>
                                {activity.name}
                              </Option>
                            ))}
                          </Select>
                        </Form.Item>
                      </Col>
                    </Row>
                  </Panel>
                </CustomCollapse>
              </>
            )}
          </div>
          {/* AD Hoc flow completed */}

          <div className={`${styles.container}`}>
            {user?.user_details?.role === 'user' ||
            user?.user_details?.role === 'admin' ||
            user?.user_details?.role === 'approver' ? (
              <>
                <CustomCollapse
                  expandIcon={({ isActive }) => (
                    <DownOutlined rotate={isActive ? 180 : 0} />
                  )}
                  expandIconPosition="end"
                  bordered={false}
                  defaultActiveKey={['1']}
                >
                  <Panel
                    header={
                      <Badge
                        className={`${styles.badge}`}
                        count={2}
                        dot={false}
                      />
                    }
                    extra="Add envelope details"
                    key="2"
                  >
                    <Row
                      className="mt-4"
                      gutter={{
                        xs: 16,
                        sm: 32,
                        md: 30,
                        lg: 48
                      }}
                    >
                      <Col className="gutter-row" xs={24} xl={24}>
                        <FormItem
                          label="Description"
                          name="description"
                          rules={[
                            {
                              required: true,
                              message: 'Enter Description'
                            }
                          ]}
                        >
                          <TextArea placeholder="Enter description "></TextArea>
                        </FormItem>
                      </Col>
                    </Row>
                  </Panel>
                </CustomCollapse>

                <br />

                {/* Upload Documents */}
                <CustomCollapse
                  expandIcon={({ isActive }) => (
                    <DownOutlined rotate={isActive ? 180 : 0} />
                  )}
                  expandIconPosition="end"
                  bordered={false}
                  defaultActiveKey={['1']}
                >
                  <Panel
                    header={
                      <Badge
                        className={`${styles.badge}`}
                        count={3}
                        dot={false}
                      />
                    }
                    extra="Upload documents"
                    key="3"
                  >
                    {fileList?.length ? (
                      <DisplayFiles
                        key={fileList.name}
                        files={fileList}
                        handleOnChangePdfUpload={handleOnChangePdfUpload}
                        handleOnDropPdfUpload={handleOnDropPdfUpload}
                        removeInputFields={removeInputFields}
                      />
                    ) : (
                      <Dragger
                        // name="file"
                        name="documets"
                        accept=".pdf"
                        listType="picture-card"
                        fileList={fileList} // Bind the dynamic file list
                        className={`avatar-uploader ${styles.upload}`}
                        onChange={handleOnChangePdfUpload}
                        onDrop={handleOnDropPdfUpload}
                        customRequest={({ onSuccess }) => {
                          setTimeout(() => {
                            onSuccess('ok');
                          }, 0);
                        }}
                        multiple={true}
                        showUploadList={false}
                      >
                        <div className="p-3">
                          <div className={`${styles.upgrade}`}>
                            <Image
                              className={`df-jb-ac ${styles.image}`}
                              src={upload}
                              preview={false}
                            />
                          </div>

                          <div className={`${styles.upgrade}`}>
                            <span>Drag and drop your files here</span>
                          </div>
                          <div className={`mb-2 ${styles.upgrade}`}>
                            <span>or</span>
                          </div>
                          <div className={`${styles.upgrade}`}>
                            <CustomButton type="primary">Upload</CustomButton>
                          </div>
                        </div>
                      </Dragger>
                    )}
                  </Panel>
                </CustomCollapse>
              </>
            ) : (
              ''
            )}
            <br />

            {/* Add Approvers Code */}
            <CustomCollapse
              expandIcon={({ isActive }) => (
                <DownOutlined rotate={isActive ? 180 : 0} />
              )}
              expandIconPosition="end"
              bordered={false}
              defaultActiveKey={['1']}
            >
              <Panel
                header={
                  <Badge
                    className={`${styles.badge}`}
                    count={user?.user_details.role === 'user' ? 4 : 1}
                    dot={false}
                  />
                }
                extra={
                  env_type === 'standard' ? 'Approvers List' : 'Add approvers'
                }
                key="4"
              >
                {/* {env_type !== 'standard' ? (
                  <div>
                    <Checkbox
                      className={`font-14`}
                      onChange={onCheckBoxToggle}
                      defaultChecked={chcked}
                      checked={signOrder}
                    >
                      Enable set signing order
                    </Checkbox>
                  </div>
                ) : (
                  ''
                )} */}
                <br />
                <DragDropContext onDragEnd={handleDrop}>
                  <Droppable
                    droppableId="list-container"
                    className={`${styles.approver_line}`}
                  >
                    {(provided) => (
                      <div
                        className="list-container"
                        {...provided.droppableProps}
                        ref={provided.innerRef}
                      >
                        {itemList.map((item, index) => (
                          <Draggable
                            key={item.id}
                            draggableId={`${item.id}`}
                            index={index}
                          >
                            {(provided) => (
                              <>
                                <div
                                  className={
                                    env_type === 'standard'
                                      ? `item-container ${styles.nodragndrop}`
                                      : 'item-container'
                                  }
                                  ref={provided.innerRef}
                                  {...provided.dragHandleProps}
                                  {...provided.draggableProps}
                                >
                                  <Row
                                    gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}
                                  >
                                    <Col className="gutter-row" span={1}>
                                      <div
                                        className={`
                                        ${
                                          item.ind > 1
                                            ? `${styles.step} ${styles.v_stepper}${styles.circle} `
                                            : ''
                                        } mt-2`}
                                      >
                                        <div className={`${styles.circle}`}>
                                          <p>{index + 1}</p>
                                        </div>
                                        <div
                                          className={
                                            itemList.length !== index + 1
                                              ? `${styles.line}`
                                              : ''
                                          }
                                        ></div>
                                      </div>
                                    </Col>
                                    {env_type !== 'standard' ? (
                                      <Col className="gutter-row" span={1}>
                                        <div className="mt-2">
                                          <HolderOutlined />
                                        </div>
                                      </Col>
                                    ) : (
                                      ''
                                    )}
                                    <Col className="gutter-row" xs={17} xl={16}>
                                      <div
                                        className={
                                          item.envtype !== ''
                                            ? `${styles.approverdisIndex}`
                                            : `${styles.approverIndex}`
                                        }
                                      >
                                        <FormItem
                                          {...item}
                                          rules={[
                                            {
                                              required: true,
                                              whitespace: true,
                                              message: 'This field is required'
                                            }
                                          ]}
                                          name="approvers"
                                          noStyle
                                          className="aasdsad"
                                        >
                                          <ReactSearchAutocomplete
                                            fuseOptions={{
                                              keys: ['name', 'email']
                                            }}
                                            onSearch={(e) =>
                                              handleApproverSearch(e)
                                            }
                                            inputDebounce={300}
                                            items={selectedRows}
                                            showIcon={false}
                                            formatResult={formatResult}
                                            placeholder="Select Approver"
                                            onSelect={onSelect}
                                            autocomplete="on"
                                            // showClear={false}
                                            showClear
                                            styling={{
                                              height: '34px',
                                              border: '1px solid darkgreen',
                                              borderRadius: '4px',
                                              backgroundColor: 'white',
                                              boxShadow: 'none',
                                              fontSize: '12px',
                                              clearIconMargin: '3px 8px 0 0',
                                              zIndex: `${zInd - index}`
                                            }}
                                            inputSearchString={
                                              selectedOption[index]
                                                ? selectedOption[index]
                                                    .job_title
                                                  ? selectedOption[index]
                                                      .display_name +
                                                    ' - (' +
                                                    selectedOption[index]
                                                      .job_title +
                                                    ')' +
                                                    ' (' +
                                                    selectedOption[index]
                                                      .email +
                                                    ')'
                                                  : selectedOption[index].name +
                                                    ' (' +
                                                    selectedOption[index]
                                                      .email +
                                                    ')'
                                                : ''
                                            }
                                          />
                                        </FormItem>
                                      </div>
                                    </Col>
                                    {/* <Col className="gutter-row" span={4}>
                                    <div className="mt-2">
                                      <b>Needs to sign</b>
                                    </div>
                                  </Col> */}
                                    {env_type === 'ad_hoc' ||
                                    item.value === '' ? (
                                      <span onClick={() => removeInput(index)}>
                                        <Delete
                                          className={`cursor-pointer ${styles.delete_icon}`}
                                        />
                                      </span>
                                    ) : (
                                      ''
                                    )}
                                  </Row>
                                </div>
                                <br />
                              </>
                            )}
                          </Draggable>
                        ))}
                        {provided.placeholder}
                      </div>
                    )}
                  </Droppable>
                  {env_type !== 'standard' ? (
                    <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                      <Col className="gutter-row" span={4}>
                        <div type="button" onClick={() => addInput()}>
                          <span className={`cursor-pointer ${styles.span_add}`}>
                            <PlusCircleFilled />
                            {'\u00a0'} Add Approvers
                          </span>
                        </div>
                      </Col>
                    </Row>
                  ) : (
                    ''
                  )}
                </DragDropContext>

                {/*  */}
              </Panel>
            </CustomCollapse>
            <br />
            {user?.user_details?.role === 'user' ||
            user?.user_details?.role === 'admin' ? (
              <>
                <CustomCollapse
                  expandIcon={({ isActive }) => (
                    <DownOutlined rotate={isActive ? 180 : 0} />
                  )}
                  expandIconPosition="end"
                  bordered={false}
                >
                  <Panel
                    className={` ${styles.panel}`}
                    header={
                      <Badge
                        className={` ${styles.badge}`}
                        count={5}
                        dot={false}
                      />
                    }
                    extra="Add message"
                    key="5"
                  >
                    <FormItem
                      label="Add messsage"
                      name="emailmessage"
                      rules={[
                        {
                          required: false,
                          message: 'Enter Total value'
                        }
                      ]}
                    >
                      <TextArea
                        className="gutter-row"
                        xs={24}
                        xl={24}
                        placeholder={'Enter message'}
                        // value={emailMessage} // Bind the state to the value
                        onChange={handleTextAreaChange}
                      />
                    </FormItem>
                  </Panel>
                </CustomCollapse>
              </>
            ) : (
              ''
            )}
            <br />
          </div>
        </Form>
      </div>
      {/* )} */}
    </>
  );
};

export default cloningPage;
